// CLIENT Configuration
// uses defaults

// Kafka Client Id
export const KAFKA_PRODUCER_CLIENT_ID =
  "ccloud-nodejs-client-b687b59c-f103-40b8-8dba-84755d2dc71a";

// KAFKA CONSUMER CONFIGURATION

// CONSUMER Configuration
// This section contains properties related to the Kafka consumer configuration.
// ANY PROPERTY NOT EXPLICITLY DEFINED HERE WILL BE THE DEFAULT CONFLUENT KAFKA JAVASCRIPT CONFIGURATION

export const PARTITIONS_CONSUMED_CONCURRENTLY = 1;

export const CONSUMER_SESSION_TIMEOUT = 120000;
export const MAX_POLL_INTERVAL = 2000000;
export const HEARTBEAT_INTERVAL = 3000;
export const INITIAL_RETRY_TIME = 100;
export const RETRY_BACKOFF = 10000;
export const RECONNECT_BACKOFF = 1000;
export const RECONNECT_BACKOFF_MAX = 10000;
export const RETRIES = 20;
export const PARTITION_ASSIGNMENT_STRATEGY = "cooperative-sticky";
export const MAX_FAILURE_RECONNECTS = 10;
export const FAILIURE_RECONECT_INTERVAL = 3000;
export const AUTO_OFFSET_RESET = "latest";
export const DEV_KAFKA_CONFIG = {
  "max.poll.interval.ms": 10000, // 10 seconds
  "session.timeout.ms": 6000, // 6 seconds
  "heartbeat.interval.ms": 2000, // 2 seconds
  "retry.backoff.ms": 100, // 100ms
  "retry.backoff.max.ms": 1000, // 1 second
  "reconnect.backoff.ms": 100, // 100ms
  "reconnect.backoff.max.ms": 1000, // 1 second
  "partition.assignment.strategy": "roundrobin",
  "auto.offset.reset": "latest",
  "enable.auto.commit": false,
};

// TOPICS
export const CONTACT_BATCH_CREATIONS_TOPIC = "contact-batch-creations";
export const REEVALUATE_BATCH_TOPIC = "reevaluate-batch";
export const USER_EVALUATION_TOPIC = "user-evaluations";
export const REPO_EVALUATION_TOPIC = "repo-evaluations";
export const USER_DEPS_EVALUATION_TOPIC = "user-dependencies-evaluations";
export const REPO_DEPS_EVALUATION_TOPIC = "repo-dependencies-evaluations";
export const LIST_EVALUATIONS_TOPIC = "list-evaluations";
export const OVERVIEW_EVALUATIONS_TOPIC = "overview-evaluations";
const getConsumerGroup = (topic: string) => `${topic}-consumer-group`;
// CONSUMER GROUP IDS
export const CONTACT_BATCH_CREATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  CONTACT_BATCH_CREATIONS_TOPIC
);

export const REEVALUATE_BATCH_CONSUMER_GROUP_ID = getConsumerGroup(
  REEVALUATE_BATCH_TOPIC
);
export const USER_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  USER_EVALUATION_TOPIC
);
export const REPO_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  REPO_EVALUATION_TOPIC
);
export const USER_DEPS_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  USER_DEPS_EVALUATION_TOPIC
);
export const REPO_DEPS_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  REPO_DEPS_EVALUATION_TOPIC
);

export const LIST_EVALUATIONS_CONSUMER_GROUP_ID =
  "list-evaluations-consumer-group";
export const OVERVIEW_EVALUATIONS_CONSUMER_GROUP_ID =
  "overview-evaluations-consumer-group";

export const LIST_PRIORITIY_CUTOFF = 1001;
export const PRIORITY_PARTITION_COUNT = 6;
export const PARTITION_NUMBER_DEFAULT = 20;
