import { AxiosError, isAxiosError } from "axios";

export const formatAxiosError = (
  error: AxiosError | Error | undefined | unknown
): string => {
  if (!error) return "No error information available.";

  if (isAxiosError(error)) {
    const status = error.response?.status ?? "Unknown Status";
    const data = JSON.stringify(error.response?.data ?? "No data");
    return `API Error (${status}): ${data}`;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return "Unrecognized error type.";
};

export const errorToString = (error: Error | undefined) => {
  let errorMessage = null;
  if (error) {
    if (typeof error === "string") {
      errorMessage = error;
    } else if (typeof error === "object") {
      if ("message" in error) {
        errorMessage = error.message;
      } else {
        errorMessage = JSON.stringify(error);
      }
    }
  }
  return errorMessage;
};
