import axios from "axios";
import { Authentication, formatAxiosError } from "@openqlabs/utils";
import { isGithubRateLimitsResponse } from "./types";
import { mockFullRateLimit } from "./mockFullRateLimitResponse";

export async function fetchRateLimits(authenticator: Authentication.Manager) {
  try {
    const response = await axios.get("https://api.github.com/rate_limit", {
      headers: {
        Authorization: `Bearer ${authenticator.getAccessToken()}`,
      },
    });
    const data = response.data;

    if (!isGithubRateLimitsResponse(data)) {
      return mockFullRateLimit.resources;
    }

    return data.resources;
  } catch (error) {
    const formattedError = formatAxiosError(error);
    console.error(
      `Error fetching rate limits: ${formattedError} -> (Access Token: ${authenticator.getAccessToken()})`
    );
    return mockFullRateLimit.resources;
  }
}
